html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: JosefinSans;
}

#__next {
  height: 100%;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#pdf-controls {
  justify-content: center;
}

.react-colorful .color-picker {
  width: 70% !important;
  border-radius: 0 !important;
}

.react-colorful__last-control {
  border-radius: 0 !important;
}

.react-colorful__saturation {
  border-radius: 0px !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
